import React from "react"
import PortableText from "@sanity/block-content-to-react"
import {graphql, Link} from "gatsby"
import {Layout, ModalLayout} from "../components/layout"
import {H2, H3} from "../components/Typography"
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import styled from "styled-components"
import Seo from "../components/seo"
import * as Sections from "../components/SectionElements"
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import * as Serializer from "../components/Serializer"

const NavigationButtons = styled.div`
  width: 100%;
  position: fixed;
  top: 0;

  @media ${props => props.theme.breakpoints.desktop} {
    max-width: 900px;
    width: 100%;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
  }
`
const CloseButton = styled.button`
  position: absolute;
  right: ${props => props.theme.spacings.xSmall};
  top: ${props => props.theme.spacings.xxSmall};
  background-color: transparent;
  font-size: 40px;
  font-weight: 200;
  line-height: 0.5;

  > a {
    padding: 0px;
    border-radius: 0;
    background-color: transparent;
    color: ${props => props.color ? props.color : props.theme.colors.secondary};
  }

  @media ${props => props.theme.breakpoints.desktop} {
    // right: ${props => props.theme.spacings.section};
    font-size: 60px;
  }
`
const TextBlock = styled(PortableText)`
  >p:last-child {
    padding-bottom: 0 !important;
  }
`
const FirmsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
`

const ImageDiv = styled.div`
  padding: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
  width: 100px;

  @media ${props => props.theme.breakpoints.desktop} {
    padding: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
    width: 150px;
  }
`

const Calendar = styled.table`
    margin-top: -${props => props.theme.spacings.xSmall};
    margin-left: auto;
    margin-right: auto;
    border-collapse: separate; 
    border-spacing: ${props => props.theme.spacings.xxSmall} ${props => props.theme.spacings.xSmall};
`

const CalendarRow = styled.tr`
  padding-bottom: ${props => props.theme.spacings.small};
`

const CalendarPeriod = styled.td`
    font-size: 16px; 
    line-height: 22px; 
    font-weight: 400;
    text-align: right;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
`

const Weekday = styled.td`
    vertical-align: bottom;
  > span {
    margin-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
    background-color: ${props => props.color ? props.color : props.theme.colors.secondary};
    padding: 2px 5px;
    border-radius: 5px;
    color: ${props => props.color ? props.color : props.theme.colors.grey3};
  }
`
const WeekdayActive = styled.td`
    vertical-align: bottom;
  > span {
    margin-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
    background-color: ${props => props.color ? props.color : props.theme.colors.flash};
    padding: 2px 5px;
    border-radius: 5px;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
  }
`
const AlternanceEntreprise = styled.span`
  font-weight: 400;
  background-color: ${props => props.color ? props.color : props.theme.colors.flash};
  padding: 2px 5px;
  border-radius: 5px;
  color: ${props => props.color ? props.color : props.theme.colors.foreground};
`
const AlternanceEcole = styled.span`
  font-weight: 400;
  background-color: ${props => props.color ? props.color : props.theme.colors.secondary};
  padding: 2px 5px;
  border-radius: 5px;
  color: ${props => props.color ? props.color : props.theme.colors.grey3};
`


const AlternancePage = ({data}) => {
    const Node = data.allSanityAlternance.nodes[0]

    return (
    <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
          <>
              {modal ? (
                <>
                <Seo title="Alternance"/>
                <NavigationButtons>
                  <CloseButton><Link to={closeTo} state={{noScroll: true}}>&#215;</Link></CloseButton>
                </NavigationButtons>
                <ModalLayout>
                    <Sections.CenteredSection>
                        <H2>Alternance</H2>
                        <TextBlock
                        blocks={Node._rawGeneralIntro}
                        serializers = {Serializer.block}
                        />
                    </Sections.CenteredSection>
                    <Sections.CenteredSection>
                        <FirmsBox>
                            {Node.alternance_firms_array.map((firm, i) => {
                                const image = getImage(firm.logo.asset)
                                return (
                                <ImageDiv key={i}><a href={firm.extUrl} target="_blank" rel='noopener noreferrer'><GatsbyImage image={image} alt={firm.firm_name}/></a></ImageDiv>
                                )
                            }   
                            )}
                        </FirmsBox>
                    </Sections.CenteredSection>
                    <Sections.CenteredSection>
                        <H3>Contrat d'apprentissage</H3>
                        <TextBlock
                        blocks={Node._rawContratApprentissage}
                        serializers = {Serializer.block}
                        />
                    </Sections.CenteredSection>
                    <Sections.CenteredSection>
                        <H3>Jours en <AlternanceEntreprise>entreprise</AlternanceEntreprise> | <AlternanceEcole>école</AlternanceEcole></H3>
                            <Calendar>
                                <tbody>
                                {Node.calendar.map((period,i) => {
                                    return (
                                    <CalendarRow key={i}>
                                        <CalendarPeriod>{period.dates} &rarr;</CalendarPeriod>
                                        {period.weekdays.includes("lun") ? (
                                                <WeekdayActive><span>lun</span></WeekdayActive>
                                         ) : (
                                                <Weekday><span>lun</span></Weekday>
                                         )}
                                        {period.weekdays.includes("mar") ? (
                                                <WeekdayActive><span>mar</span></WeekdayActive>
                                         ) : (
                                                <Weekday><span>mar</span></Weekday>
                                         )}
                                        {period.weekdays.includes("mer") ? (
                                                <WeekdayActive><span>mer</span></WeekdayActive>
                                         ) : (
                                                <Weekday><span>mer</span></Weekday>
                                         )}
                                        {period.weekdays.includes("jeu") ? (
                                                <WeekdayActive><span>jeu</span></WeekdayActive>
                                         ) : (
                                                <Weekday><span>jeu</span></Weekday>
                                         )}
                                        {period.weekdays.includes("ven") ? (
                                                <WeekdayActive><span>ven</span></WeekdayActive>
                                         ) : (
                                                <Weekday><span>ven</span></Weekday>
                                         )}
                                    </CalendarRow>
                                    )
                                })}
                                </tbody>
                            </Calendar>
                    </Sections.CenteredSection>
                    {Node._rawSuiviApprentissage ? (
                      <Sections.CenteredSection>
                          <H3>Suivi d'apprentissage</H3>
                          <TextBlock
                          blocks={Node._rawSuiviApprentissage}
                          serializers = {Serializer.block}
                          />
                      </Sections.CenteredSection>
                    ) : (<></>)}
                    {Node._rawAideEmbauche ? (
                    <Sections.CenteredSection>
                        <H3>Aide à l'embauche pour les entreprises</H3>
                        <TextBlock
                        blocks={Node._rawAideEmbauche}
                        serializers = {Serializer.block}
                        />
                    </Sections.CenteredSection>
                    ) : (<></>)}
                    {Node._rawRemuneration ? (
                    <Sections.CenteredSection>
                        <H3>Rémunération de l'apprenti</H3>
                        <TextBlock
                        blocks={Node._rawRemuneration}
                        serializers = {Serializer.block}
                        />
                    </Sections.CenteredSection>
                    ) : (<></>)}
                    {Node._rawPriseEnCharge ? (
                    <Sections.CenteredSection>
                        <H3>Prise en charge du coût de formation</H3>
                        <TextBlock
                        blocks={Node._rawPriseEnCharge}
                        serializers = {Serializer.block}
                        />
                    </Sections.CenteredSection>
                    ) : (<></>)}
                    {Node._rawExonerations ? (
                    <Sections.CenteredSection>
                        <H3>Exonérations</H3>
                        <TextBlock
                        blocks={Node._rawExonerations}
                        serializers = {Serializer.block}
                        />
                    </Sections.CenteredSection>
                    ) : (<></>)}
                </ModalLayout>
                </>
              ) : (
                <>
                <Seo title="Alternance"/>
                <Layout homebutton={true}>
                    <Sections.CenteredSection>
                        <H2>Alternance</H2>
                        <TextBlock
                        blocks={Node._rawGeneralIntro}
                        serializers = {Serializer.block}
                        />
                    </Sections.CenteredSection>
                    <Sections.CenteredSection>
                        <FirmsBox>
                            {Node.alternance_firms_array.map((firm, i) => {
                                const image = getImage(firm.logo.asset)
                                return (
                                <ImageDiv key={i}><a href={firm.extUrl} target="_blank" rel='noopener noreferrer'><GatsbyImage image={image} alt={firm.firm_name}/></a></ImageDiv>
                                )
                            }   
                            )}
                        </FirmsBox>
                    </Sections.CenteredSection>
                    <Sections.CenteredSection>
                        <H3>Contrat d'apprentissage</H3>
                        <TextBlock
                        blocks={Node._rawContratApprentissage}
                        serializers = {Serializer.block}
                        />
                    </Sections.CenteredSection>
                    <Sections.CenteredSection>
                        <H3>Jours en <AlternanceEntreprise>entreprise</AlternanceEntreprise> | <AlternanceEcole>école</AlternanceEcole></H3>
                            <Calendar>
                                <tbody>
                                {Node.calendar.map((period,i) => {
                                    return (
                                    <CalendarRow key={i}>
                                        <CalendarPeriod>{period.dates} &rarr;</CalendarPeriod>
                                        {period.weekdays.includes("lun") ? (
                                                <WeekdayActive><span>lun</span></WeekdayActive>
                                         ) : (
                                                <Weekday><span>lun</span></Weekday>
                                         )}
                                        {period.weekdays.includes("mar") ? (
                                                <WeekdayActive><span>mar</span></WeekdayActive>
                                         ) : (
                                                <Weekday><span>mar</span></Weekday>
                                         )}
                                        {period.weekdays.includes("mer") ? (
                                                <WeekdayActive><span>mer</span></WeekdayActive>
                                         ) : (
                                                <Weekday><span>mer</span></Weekday>
                                         )}
                                        {period.weekdays.includes("jeu") ? (
                                                <WeekdayActive><span>jeu</span></WeekdayActive>
                                         ) : (
                                                <Weekday><span>jeu</span></Weekday>
                                         )}
                                        {period.weekdays.includes("ven") ? (
                                                <WeekdayActive><span>ven</span></WeekdayActive>
                                         ) : (
                                                <Weekday><span>ven</span></Weekday>
                                         )}
                                    </CalendarRow>
                                    )
                                })}
                                </tbody>
                            </Calendar>
                    </Sections.CenteredSection>
                    {Node._rawSuiviApprentissage ? (
                      <Sections.CenteredSection>
                          <H3>Suivi d'apprentissage</H3>
                          <TextBlock
                          blocks={Node._rawSuiviApprentissage}
                          serializers = {Serializer.block}
                          />
                      </Sections.CenteredSection>
                    ) : (<></>)}
                    {Node._rawAideEmbauche ? (
                    <Sections.CenteredSection>
                        <H3>Aide à l'embauche pour les entreprises</H3>
                        <TextBlock
                        blocks={Node._rawAideEmbauche}
                        serializers = {Serializer.block}
                        />
                    </Sections.CenteredSection>
                    ) : (<></>)}
                    {Node._rawRemuneration ? (
                    <Sections.CenteredSection>
                        <H3>Rémunération de l'apprenti</H3>
                        <TextBlock
                        blocks={Node._rawRemuneration}
                        serializers = {Serializer.block}
                        />
                    </Sections.CenteredSection>
                    ) : (<></>)}
                    {Node._rawPriseEnCharge ? (
                    <Sections.CenteredSection>
                        <H3>Prise en charge du coût de formation</H3>
                        <TextBlock
                        blocks={Node._rawPriseEnCharge}
                        serializers = {Serializer.block}
                        />
                    </Sections.CenteredSection>
                    ) : (<></>)}
                    {Node._rawExonerations ? (
                    <Sections.CenteredSection>
                        <H3>Exonérations</H3>
                        <TextBlock
                        blocks={Node._rawExonerations}
                        serializers = {Serializer.block}
                        />
                    </Sections.CenteredSection>
                    ) : (<></>)}
                </Layout>
                </>
              )}
          </>
        )}
    </ModalRoutingContext.Consumer>
    )
};

export default AlternancePage

export const query = graphql`
query AlternanceQuery {
    allSanityAlternance {
        nodes {
          _rawGeneralIntro
          _rawContratApprentissage
          _rawSuiviApprentissage
          _rawAideEmbauche
          _rawRemuneration
          _rawPriseEnCharge
          _rawExonerations
          calendar {
            dates
            weekdays
          }
          alternance_firms_array {
              extUrl
              firm_name
              logo {
                asset {
                  gatsbyImageData(
                    width: 200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
          }
        }
    }
}
`;